import React from 'react';
import styles from './ReportSidebar.module.scss';
import { NavLink } from "react-router-dom";

interface ReportSidebarProps {
}
export const ReportSidebar = (props: ReportSidebarProps) => {
    return (
        <div className={styles.reportSidebarWrapper}>
            <div className={styles.sidebarContent}>
                <ul className={styles.reportMapMenu}>
                    <li>
                        <NavLink to="/report-suppliers" activeClassName={styles.sidebarActiveLink}>SUPPLIERS</NavLink>
                    </li>
                    <li>
                        <NavLink to="/report-shipping" activeClassName={styles.sidebarActiveLink}>SHIPPING INTERMEDIARIES</NavLink>
                    </li>
                    <li>
                        <NavLink to="/report-customers" activeClassName={styles.sidebarActiveLink}>CUSTOMERS</NavLink>
                    </li>
                    <li>
                        <NavLink to="/report-components" activeClassName={styles.sidebarActiveLink}>COMPONENTS</NavLink>
                    </li>
                    <li>
                        <NavLink to="/report-products" activeClassName={styles.sidebarActiveLink}>SUPPLY CHAIN OVERVIEW</NavLink>
                    </li>

                </ul>


            </div>
        </div>
    )
};