import * as React from 'react';
import { ComponentNodeModel } from './ComponentNodeModel';
import { ComponentNodeWidget } from './ComponentNodeWidget';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';

export class ComponentNodeFactory extends AbstractReactFactory<ComponentNodeModel, DiagramEngine> {
	constructor() {
		super('component');
	}

	generateReactWidget(event: any): JSX.Element {
		return <ComponentNodeWidget engine={this.engine} node={event.model} />;
	}

	generateModel(event: any): ComponentNodeModel {
		return new ComponentNodeModel();
	}
}
