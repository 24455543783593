import * as React from 'react';
import { ProductNodeModel } from './ProductNodeModel';
import { ProductNodeWidget } from './ProductNodeWidget';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';



export class ProductNodeFactory extends AbstractReactFactory<ProductNodeModel, DiagramEngine> {
	constructor() {
		super('product');
	}
	
	generateReactWidget(event: any): JSX.Element {
		return <ProductNodeWidget engine={this.engine} node={event.model} />;
	}

	generateModel(event: any): ProductNodeModel {
		return new ProductNodeModel();
	}
}
