import * as React from 'react';
import * as _ from 'lodash';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { ComponentNodeModel } from './ComponentNodeModel';
import { DefaultPortLabel, DefaultPortModel } from '@projectstorm/react-diagrams-defaults';
import styled from '@emotion/styled';
import { useAppContext } from '../../../../services/AppContext';
import { NodeMenu } from '../../../../fragments/NodeMenu/NodeMenu';
import { ItemForm } from '../../../../fragments/ItemForm/ItemForm';
import styles from '../product/ProductNodeWidget.module.scss';
import { useAuth } from '../../../../services/AuthContext';

namespace S {
	export const Node = styled.div<{ background: string; selected: boolean }>`
        background-color: ${(p) => (p.selected ? '#acca5d!important' : 'white!important')};
        border-radius: 5px;
		font-family: sans-serif;
		color: white;
		border: solid 2px black;
		overflow: visible;
		font-size: 11px;
		border: solid 2px ${(p) => (p.selected ? '#acca5d!important' : 'white')};
	`;

	export const Title = styled.div`
		background: rgba(0, 0, 0, 0.3);
		display: flex;
		white-space: normal;
		justify-items: center;
	`;

	export const Type = styled.div`
		flex-grow: 1;
		padding: 5px 5px;
	`;

	export const TitleName = styled.div`
		flex-grow: 1;
		padding: 5px 5px;
	`;

	export const MenuButton = styled.div`
		flex-grow: 1;
		padding: 5px 5px;
	`;

	export const Form = styled.div`
	`;

	export const Ports = styled.div`
		display: flex;
		background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
	`;

	export const PortsContainer = styled.div`
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		&:first-of-type {
			margin-right: 78%;
		}

		&:only-child {
			margin-right: 0px;
		}
	`;
}

export interface DefaultNodeProps {
	node: ComponentNodeModel;
	engine: DiagramEngine;
}

/**
 * Default node that models the DefaultNodeModel. It creates two columns
 * for both all the input ports on the left, and the output ports on the right.
 */
export function ComponentNodeWidget(props: DefaultNodeProps) {
	const appContext = useAppContext();
	const authContext = useAuth();

	const generatePort = (port:DefaultPortModel) => {
		return <DefaultPortLabel engine={props.engine} port={port} key={port.getID()} />;
	};

	const editSuccess = () => {
		appContext.hideModal();
		window.setTimeout(() => {
			appContext.diagramEngine.repaintCanvas();
		}, 0);
	}

	const editFailure = () => {
		appContext.hideModal();
	}

	const edit = (e:any) => {
		appContext.setModalContent(<ItemForm onSuccess={editSuccess} onFailure={editFailure} id={props.node.getID()} />);

		appContext.showModal();

		e.stopPropagation();
	}

	const deleteItem = (e:any) => {
		authContext.deleteNode(props.node.getID()).then(() => {
			props.node.remove();
			props.engine.repaintCanvas();
		})

		e.stopPropagation();
	}

	return (
		<S.Node className={styles.nodeWrapper}
			data-default-node-name={props.node.getOptions().name}
			selected={props.node.isSelected()}
			background="rgb(0,192,255)">
			<S.Title className={styles.nodeTitle}>
				<S.Type>Component: </S.Type>
				<S.TitleName>{props.node.getOptions().name}</S.TitleName>
				<NodeMenu>
					<div onClick={edit}>Edit</div>
					<div onClick={deleteItem}>Delete</div>
				</NodeMenu>
			</S.Title>
			<S.Ports className={styles.portWrapper}>
				<S.PortsContainer className={styles.portOut}>{_.map(props.node.getOutPorts(), generatePort)}</S.PortsContainer>
				<S.PortsContainer className={styles.portIn}>{_.map(props.node.getInPorts(), generatePort)}</S.PortsContainer>
			</S.Ports>
		</S.Node>
	);
}
