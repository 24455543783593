import React, { useEffect, useState } from 'react';
import styles from './ReportList.module.scss';
import { ReportSidebar } from "../../fragments/ReportSidebar/ReportSidebar";
import { ItemForm } from '../../fragments/ItemForm/ItemForm';
import { useAppContext } from '../../services/AppContext';
import { useAuth } from '../../services/AuthContext';

interface ReportListProps {
  type: string;
}
export const ReportList = (props: ReportListProps) => {
    const [items, setItems] = useState<{[key: string]: any}[]>([]);
    const appContext = useAppContext();
    const auth = useAuth();
    //const [rowCount, setRowCount] = React.useState<number|any>(false);
    let rowCount = 0;

	useEffect(() => {
		auth.getItems(props.type).then((response: any) => {
			setItems(response.data);
		});
	}, [props.type])

	const editItem = (id: string) => {
		appContext.setModalContent(<ItemForm onSuccess={editSuccess} onFailure={editFailure} id={id} />);

		appContext.showModal();
	}

	const getKeys = (type: string):{label: string, key: string, editLink:boolean}[] => {
		const keys:{[key:string]: {label: string, key: string, editLink:boolean}[]} = {
			product: [
				{
					label: 'PRODUCT NAME',
					key: 'name',
					editLink: true,
				},
                {
					label: 'SYSTEM GENERATED ID',
					key: 'id',
					editLink: false,
				},
                {
					label: 'SKU/INVENTORY CODE',
					key: 'sku',
					editLink: false,
				},
				{
					label: 'TYPE',
					key: 'product_type',
					editLink: false,
				},
                {
					label: 'DESCRIPTION',
					key: 'description',
					editLink: false,
				},
                {
					label: 'Critical Supply Chain Considerations',
					key: 'critical_considerations',
					editLink: false,
				},
				{
					label: 'LEAD TIME',
					key: 'sum_lead_days',
					editLink: false,
				},
				{
					label: 'LANDING COST',
					key: 'sum_landed_costs',
					editLink: false,
				},
				{
					label: 'CUSTOMS/TERIFFS',
					key: 'sum_tariffs',
					editLink: false,
				},
				{
					label: 'RECOVERY TIME',
					key: 'RecoveryTime',
					editLink: false,
				},
			],
			component: [
				{
					label: 'COMPONENT NAME',
					key: 'name',
					editLink: true,
				},
                {
					label: 'SYSTEM GENERATED ID',
					key: 'id',
					editLink: false,
				},
                {
					label: 'VENDOR PART NUMBER/SKU',
					key: 'vendor_part_number',
					editLink: false,
				},
				{
					label: 'UPC',
					key: 'upc',
					editLink: false,
				},
				{
					label: 'DESCRIPTION',
					key: 'description',
					editLink: false,
				},
                {
					label: 'VENDOR INFORMATION',
					key: 'vendor_information',
					editLink: false,
				},
                {
					label: 'COST',
					key: 'cost',
					editLink: false,
				},
				{
					label: 'CASE QUANTITY',
					key: 'case_quantity',
					editLink: false,
				},
				{
					label: 'CASE SIZE',
					key: 'case_size',
					editLink: false,
				},
				{
					label: 'TERIFF CLASSIFICATION',
					key: 'tariff_classification',
					editLink: false,
				},
				{
					label: 'APPLICABLE TRADE AGREEMENTS',
					key: 'applicable_trade_agreements',
					editLink: false,
				},
				{
					label: 'INCOTERMS',
					key: 'incoterms',
					editLink: false,
				},
			],
			supplier: [
				{
					label: 'SUPPLIER NAME',
					key: 'name',
					editLink: true,
				},
				{
					label: 'CONSIDERATIONS',
					key: 'qualifications',
					editLink: false,
				},
				{
					label: '#SYSTEM GENERATED ID',
					key: 'id',
					editLink: false,
				},
				{
					label: 'NAICS',
					key: 'naics',
					editLink: false,
				},
				{
					label: 'SUPPLIER CATEGORY',
					key: 'category',
					editLink: false,
				},
				{
					label: 'QUALITY SYSTEMS (ISO)',
					key: 'iso',
					editLink: false,
				},
				{
					label: 'ON TIME DELIVERY PERFORMANCE %',
					key: 'onTime',
					editLink: false,
				},
				{
					label: 'TERMS',
					key: 'terms',
					editLink: false,
				},
				{
					label: 'ORDER LEAD TIME REQUIREMENTS - # OF DAYS',
					key: 'lead_days',
					editLink: false,
				},
				{
					label: 'ORDER LEAD TIME REQUIREMENTS - DETAILS',
					key: 'lead_days_details',
					editLink: false,
				},
				{
					label: 'TRANSPORTATION ROUTES AVAILABLE',
					key: 'transport_routes_available',
					editLink: false,
				},
				{
					label: 'FEES CHARGED BY SUPPLIER',
					key: 'fees_charged_by_supplier',
					editLink: false,
				},
				{
					label: 'CUSTOMS/TARIFFS APPLIED BY SUPPLIER',
					key: 'tariffs_applied_by_supplier',
					editLink: false,
				},
				{
					label: 'INSURANCE COSTS APPLIED BY SUPPLIER',
					key: 'insurance_applied_by_supplier',
					editLink: false,
				},
				{
					label: 'AVERAGE PAYMENT TERMS',
					key: 'average_payment_terms',
					editLink: false,
				},
			   

			],
			customer: [
				{
					label: 'CUSTOMER NAME',
					key: 'name',
					editLink: true,
				},
				{
					label: '#SYSTEM GENERATED ID',
					key: 'id',
					editLink: false,
				},
				{
					label: 'NAICS',
					key: 'naics',
					editLink: false,
				},
				{
					label: 'CUSTOMER CATEGORY',
					key: 'customer_category',
					editLink: false,
				},
				{
					label: 'CRITICAL CUSTOMER REQUIREMENTS',
					key: 'critical_customer_requirements',
					editLink: false,
				},
				{
					label: 'SHIPMENT FREQUENCY',
					key: 'shipment_frequency',
					editLink: false,
				},
				{
					label: 'ORDER/DELIVERY REQUIREMENTS',
					key: 'order_requirements',
					editLink: false,
				},
				{
					label: 'TRANSPORT ROUTES AVAILABLE',
					key: 'transport_routes',
					editLink: false,
				},
				{
					label: 'SHIPPING INTERMEDIARIES AVAILABLE',
					key: 'Shipping_intermediaries_available',
					editLink: false,
				},
				{
					label: 'CUSTOMS/TERIFFS CHARGED',
					key: 'teriffs_applied_to_customer',
					editLink: false,
				},
				{
					label: 'INSURANCE COSTS CHARGED',
					key: 'insurance_costs',
					editLink: false,
				},
				{
					label: 'PAYMENT TERMS',
					key: 'payment_terms',
					editLink: false,
				},


			],
			shipping: [
				{
					label: 'SHIPPING INTERMEDIARY NAME',
					key: 'name',
					editLink: true,
				},
				{
					label: 'QUALIFICATIONS',
					key: 'qualifications',
					editLink: false,
				},
				{
					label: '#SYSTEM GENERATED ID',
					key: 'id',
					editLink: false,
				},
				{
					label: 'NAICS',
					key: 'naics',
					editLink: false,
				},
				{
					label: 'CATEGORY',
					key: 'category',
					editLink: false,
				},
				{
					label: 'ORDER LEAD TIME REQUIREMENTS - # OF DAYS',
					key: 'lead_days',
					editLink: false,
				},
				{
					label: 'ORDER LEAD TIME REQUIREMENTS - DETAILS',
					key: 'lead_days_details',
					editLink: false,
				},
				{
					label: 'SHIPMENT FREQUENCY',
					key: 'shipment_frequency',
					editLink: false,
				},
				{
					label: 'FEES CHARGED BY SHIPPING INTERMEDIARY',
					key: 'fees_charged_by_si',
					editLink: false,
				},
				{
					label: 'CUSTOMS/TARIFFS CHARGED BY SHIPPING INTERMEDIARY',
					key: 'tariffs_applied_by_si',
					editLink: false,
				},
				{
					label: 'AVERAGE PAYMENT TERMS',
					key: 'average_payment_terms',
					editLink: false,
				},


			]
		}

		return keys[type];
	}

	const editSuccess = () => {
		updateData();
	}

	const editFailure = () => {

	}

	const listHeaders = (type:string):JSX.Element[] => {
		let headers:JSX.Element[] = getKeys(type).map((item, index) => {
			return (<th key={index}>{item.label}</th>);
		});

		headers.push(<th key="_delete"></th>);

		return headers;
	}

	const deleteModel = (id:string) => {
		auth.deleteModel(id).then(() => {
			updateData();
		});
	}

	const updateData = () => {
		auth.getItems(props.type).then((response: any) => {
			setItems(response.data);
		});
	}

	const listItems = (type:string):JSX.Element[] => {
		let rows:JSX.Element[] = [];
		for(let item of items) {
			rows.push(
				(
				<tr key={item.id}>
					{getKeys(type).map((key, index) => {
						if (key.editLink) {
							return (<td className={styles.reportLink} key={key.key} onClick={() => editItem(item.id)}>{item[key.key]}</td>);
						}
						else {
							return (<td key={key.key}>{item[key.key]}</td>);
						}
						
					})}
					<td onClick={() => deleteModel(item.id)} className={styles.deleteBtn}>DELETE</td>
				</tr>
				)
			);
		}

		rowCount = rows.length;
		return rows;
	}

	const addNew = (e: any) => {
		editItem(props.type);

		e.stopPropagation();
	}
	return (
		<div>
			<ReportSidebar></ReportSidebar>
			<div className={styles.reportContentWrapper}>
				<div className={styles.sectionContentWrapper}>
					
					<h1 className={styles.reportPageTitle}>{
						(() => {
							if (props.type === 'supplier') {
								return 'SUPPLIERS REPORT';
							}
							else if (props.type === 'shipping') {
								return 'SHIPPING INTERMEDIARY REPORT';
							}
							else if (props.type === 'component') {
								return 'COMPONENT REPORT';
							}
							else if (props.type === 'product') {
								return 'PRODUCTS REPORT';
							}
							else if (props.type === 'customer') {
								return 'CUSTOMERS REPORT';
							}
							else {
								return 'REPORT'
							}
						})()

					}</h1>
					<ul className={styles.reportActionMenu}>
						<li>
							<a className={styles.reportActionButton} href={'/report_csv/' + props.type}>DOWNLOAD </a>
						</li>                        <li>
							<a className={styles.reportActionButtonAlt} onClick={addNew}>ADD NEW </a>
						</li>
					</ul>
					<div className={styles.clearfix}></div>
				</div>
				<div className={styles.reportTableWrapper}>
					<table>
						<thead>
							<tr>
								{ listHeaders(props.type) }
							</tr>
						</thead>
						<tbody>
							{ listItems(props.type) }
						</tbody>
					</table>
				</div>
				<div className={styles.reportFooterWrapper}>
					<strong>TOTAL: {rowCount}</strong>
				</div>
			</div>
		</div>
	);
};