import { Action, InputType, MoveItemsState } from '@projectstorm/react-canvas-core';
import * as _ from 'lodash';
import {
	PointModel,
	DiagramEngine,
	PortModel,
	LinkModel,
} from '@projectstorm/react-diagrams-core';
import { NodeModel } from '@projectstorm/react-diagrams';
import { ProductNodeModel } from '../entities/nodes/product/ProductNodeModel';
import axios from 'axios';
import { MyLinkModel } from '../entities/links/MyLinkModel';

export class MyDragDiagramItemsState extends MoveItemsState<DiagramEngine> {
	constructor() {
		super();
		this.registerAction(
			new Action({
				type: InputType.MOUSE_UP,
				fire: (event: any) => {
					const item = this.engine.getMouseElement(event.event);

					if (item instanceof NodeModel) {
						let itemsToSave: any[] = [];

						itemsToSave.push(item.serialize());

						const WPAPI = axios.create({
							baseURL: process.env.REACT_APP_WP_API
						});
						WPAPI.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt');

						WPAPI.post('supplychain/v1/saveItems', itemsToSave).then(response => {
						});


						// trying to set the node as selected and display the state 


						let hasParents = true;
						let itemToCheck = item;

						let currentDepth = 0;

						let currentLeadDays = 0;
						let foundProductNode = item;
						let currentCost = 0;

						while (hasParents && currentDepth < 15) {
							currentDepth += 1;


							// we're going to get the lead time if its a shipper
							if (itemToCheck.getOptions().type == 'shipping' || itemToCheck.getOptions().type == 'component' || itemToCheck.getOptions().type == 'supplier') {
								console.log(itemToCheck.getOptions().type);

								let feesId = 'fees_charged_by_si';
								if(itemToCheck.getOptions().type == 'supplier')
								{
									feesId = 'fees_charged_by_supplier';
								}else if(itemToCheck.getOptions().type == 'component')
								{
									feesId = 'cost';
								}
								WPAPI.get('supplychain/v1/getItemField/' + itemToCheck.getID() + '/'+feesId).then(response2 => {

									let thisCost = response2.data.defaultValue;
									console.log(response2);
									if (thisCost) {
										currentCost += Number(thisCost);
									}
								});
								WPAPI.get('supplychain/v1/getItemField/' + itemToCheck.getID() + '/lead_days').then(response => {

									let leadDays = response.data.defaultValue;
									if (leadDays) {
										currentLeadDays += Number(leadDays);
									}


								
										if (foundProductNode instanceof ProductNodeModel) {
											foundProductNode.setOverlayData({lead_days:currentLeadDays,cost:currentCost});

											// need to toggle the selection in order to refresh the display for overlay data.. 
											foundProductNode.setSelected(false);
											foundProductNode.setSelected(true);

										}

									


								});
							} else if (itemToCheck.getOptions().type == 'product') {


								// if the current item is the product, we pass in the lead days


								if (itemToCheck instanceof ProductNodeModel) {
									foundProductNode = itemToCheck;
									itemToCheck.setOverlayData(currentLeadDays);

								}




							}
							if (typeof itemToCheck.getPorts().In != 'undefined') {

								for (let link in itemToCheck.getPorts().In.links) {
									const linkm = itemToCheck.getPorts().In.links[link];
									if (typeof linkm != 'undefined') {
										linkm.setSelected(true);

										itemToCheck = linkm.getSourcePort().getParent();
										itemToCheck.setSelected(true);




									}
									else {

									}
									//console.log(itemToCheck);
								}
							}
							else {
								hasParents = false;
							}
						}












					}

					if (item instanceof PortModel) {
						_.forEach(this.initialPositions, (position) => {
							if (position.item instanceof PointModel) {
								const link = position.item.getParent() as LinkModel;

								// only care about the last links
								if (link.getLastPoint() !== position.item) {
									return;
								}
								if (link.getSourcePort().canLinkToPort(item)) {
									link.setTargetPort(item);

									item.reportPosition();
									this.engine.repaintCanvas();
								}
							}
						});
					}
				}
			})
		);
	}
}
